/* src/App.css */

:root {
  --light-blue: #00A9E0;
  --dark-blue: #002F6C;
  --orange: #FF6600;
  --white: #FFFFFF;
  --dark-grey: #333333;
  --black: #000000;
}

body {
  background-color: var(--black);
  color: var(--white);
  font-family: 'Arial', 'Helvetica', sans-serif;
  margin: 0;
}

.App {
  text-align: center;
  color: var(--white);
}

.App-header {
  position: relative;
  width: 100%;
  padding-top: 28.125%; /* 16:9 Aspect Ratio reduced to 50% height */
  overflow: hidden;
  margin-bottom: 20px;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo-name {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.coming-soon {
  font-size: 1.5em;
  color: var(--light-blue);
  margin: 10px 0;
}

.App-content {
  padding: 20px;
  background-color: var(--dark-grey);
  color: var(--white);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  margin: 0 auto;
}

.App-content p {
  font-size: 1.2em;
  margin: 10px 0;
  line-height: 1.5;
}
